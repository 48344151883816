.App {
  text-align: center;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App a {
  text-decoration-line: none;
}

.light {
  background: white;
  color: #292b2c;
}

.dark {
  background: #292b2c;
  color: white;
}

.dark a {
  color: rgba(118, 207, 248, 0.774);
}

.dark .about-description a {
  color: rgba(56, 140, 218, 0.904);
}

.light a {
  color: rgba(56, 140, 218, 0.904); 
}

.bubble {
  pointer-events: none; 
  
  animation-name: Float;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  position: fixed;
  top: 100%;

  opacity: 0.2;
}

@keyframes Float {
  0% {
    top: 100%;
  }
  100% {
    top: -50%;
  }
}

.custom-card {
  background-color: rgba(255, 255, 255, 0.541);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.075);
  border-radius: 1.25rem;
}

.custom-badge {
  background-image: url(../images/badge.png);
  background-size: cover;
  color: white;
  font-weight: bold;
  border-radius: 0.3rem;
  padding: 0rem 0.3rem 0.1rem 0.3rem;
  margin-left: 0.1rem;
  margin-top: 0.3rem;
  white-space: nowrap;
}

.spaced-list h1, .spaced-list h2 {
  padding: 1rem;
}

.message {
  animation-name: materialize;
  animation-duration: 100ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes materialize {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  justify-content: center;
  align-items: center;
}

.about-image {
  margin-top: 3rem;
  margin-bottom: 1rem;
  width: 95%;
  height: auto;
}

.about-image-quote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0rem;
  margin-right: 0;
}

.about-quote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
}

.about-name {
  margin-bottom: 1rem;
  padding: 1rem;
}

.about-description {
  margin-bottom: 1rem;
  text-align: left;
  padding: 1rem;
}

.about-image-name-container {
  display: none;
}

.custom-navbar {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 40; 
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
}

#slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: relative;
  z-index: 30;
  min-height: 100vh;
}

@media (max-width: 768px) {
  #slide {
    margin-top: 2rem;
  }

  .dark .custom-navbar {
    background-color: #292b2c;
  }
  
  .light .custom-navbar {
    background-color: white;
  }
}

@media (min-width: 576px) {
  .about-image {
    margin-top: 1rem;
    width: 60%;
  }
}

/* Medium sized screens - tablet */
@media (min-width: 768px) {
  .about-image {
    margin-top: 0rem;
    margin-bottom: 0rem;
    width: 40%;
  }

  .about-image-quote-container {
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;
  }

  .about-image-name-container {
    display: none;
  }

  .about-quote-name-container {
    width: 58%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-name {
    width: 100%;
    height: 40%;
    padding: 1.25rem;
    padding-top: 2rem;
    margin-bottom: 1rem;
  }

  .about-quote {
    width: 100%;
    height: 55%;
    margin-bottom: 0rem;
  }
}

/* Large sized screens */
@media (min-width: 992px) {
  .about-container {
    flex-direction: row;
  }

  .about-image {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .about-image-quote-container {
    flex-direction: column;
    justify-content: center;
    margin-right: 1.9rem;
    width: 30%;
  }

  .about-image-name-container {
    display: none;
  }

  .about-quote-name-container {
    width: 100%;
    height: auto;
  }

  .about-description {
    padding-left: 2rem;
    padding-top: 2rem;
    width: 60%;
  }

  .about-quote {
    padding: 2rem;
    height: 100%;
  }
}
